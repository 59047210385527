import React from 'react'

const HomeBlogCard = () => {
    return (
        <div className='w-[18rem] relative my-4 mx-auto rounded-xl shadow bg-white min-h-[5rem] ' >
            <div className='relative  ' >
                <img src={require('../../Assest/Figure.png')} alt="Figure" />
                <span className=' absolute left-1/2 w-10 -translate-x-1/2 border-2 border-slate-300 -bottom-3 rounded-full p-2 bg-slate-50' >
                    <img className=' '
                        src={require('../../Assest/favicon.ico')} alt="Icon" />
                </span>
            </div>
            {/* Bottom */}
            <div className='p-3 text-center relative ' >
                <h5>Artificial Intelligence </h5>
                <p className='line-clamp-3 text-slate-400 text-sm ' >Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae voluptatem temporibus excepturi voluptatibus tempora illo molestiae doloremque
                    ipsam debitis consectetur. </p>

            </div>
            <button className='p-2 left-1/2 -translate-x-1/2 absolute -bottom-3 px-2 rounded bg-rose-600 text-white text-xs ' >
                Read More
            </button>
        </div>
    )
}

export default HomeBlogCard