import React from 'react'

const ContactForm = () => {
    return (
        <div className='py-5' >
            <main className='container bg-white  min-h-[30vh] mx-auto px-0 overflow-hidden row rounded-xl ' >
                <section className=' col-sm-6  p-5 px-sm-5 poppins ' >
                    <h4 className='text-blue-600 fw-semibold ' >Get in touch </h4>
                    <p className=' ' >We are here for you! How can we help? </p>
                    <div>
                        Name
                        <input type="text" className='my-2 rounded w-full border-2 border-blue-600 p-1 ' />
                    </div>
                    <div>
                        Email
                        <input type="text" className='my-2 rounded w-full border-2 border-blue-600 p-1 ' />
                    </div>
                    <div>
                        Message
                        <textarea rows={5} type="text" className='my-2 rounded w-full border-2 border-blue-600 p-1 ' />
                    </div>
                    <p className='h-[15px] ' > </p>
                    <button className='w-full shadow rounded p-2 bg-blue-800 text-white shadow-pink-500 ' >
                        Submit
                    </button>
                </section>
                <section className='col-sm-6 contactrightbg flex ' >
                    <div className=' w-7/12 m-auto h-2/3 relative border-1 rounded-xl
                     backdrop-blur-sm bg-opacity-30 bg-slate-50 p-3 text-white ' >
                        <h4 className='w-2/3 ' >Why are waiting for you Contact Now!!!  </h4>
                        <img src={require('../Assest/women.webp')} alt="Women" className='w-60  bottom-0 absolute -right-12 ' />
                        <div className='bg-slate-50 w-12 rounded-full p-2 shadow absolute top-1/2 -left-7 ' >
                            <img src={require('../Assest/bolt.webp')} alt="Bolt" className='' />
                        </div>
                    </div>

                </section>

            </main>

        </div>
    )
}

export default ContactForm