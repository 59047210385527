import React from 'react'

const ServiceContent = () => {
    return (
        <div className='container mx-auto poppins ' >
            <img src={require('../../Assest/service1.webp')} alt="Service" />
            <p className='my-3 text-slate-600 ' >At tempus aenean sapien torquent sed diam class efficitur mus morbi eros dictum quam augue ac
                laor eet ligula libero mi commodo nibh hac fermentum orci ad pharetra consequat justo duis
                turpis lorem elit dui consectetur magnis lacinia odio per placerat vestibulum volutpat
                mauris mollis primis imperdiet posu ere ex enim gravida cras
                congue
            </p>
            <p className='my-3 text-slate-600 ' >
                pellentesque vulputate malesuada dictumst fames interdum cursus an te tellus porta
                ullamcorper accumsan non eu adipiscing integer venenatis sagittis arcu curae finibus ridi
                culus aliquam velit lobortis senectus vitae sollicitudin sit consectetuer ultricies rutrum
                parturient pede nisi nascetur habitant netus quisque elementum inceptos nam felis penatibus
                feugiat </p>
            <img src={require('../../Assest/service2.webp')} alt="Service" className='my-3 ' />
            <h4>Scalable Solutions: </h4>
            <p className='my-3 text-slate-600 ' >At tempus aenean sapien torquent sed diam class efficitur mus morbi eros dictum quam augue ac
                laor eet ligula libero mi commodo nibh hac fermentum orci ad pharetra consequat justo duis
                turpis lorem elit dui consectetur magnis lacinia odio per placerat vestibulum volutpat
                mauris mollis primis imperdiet posu ere ex enim gravida cras
                congue
            </p>
            <p className='my-3 text-slate-600 ' >
                pellentesque vulputate malesuada dictumst fames interdum cursus an te tellus porta
                ullamcorper accumsan non eu adipiscing integer venenatis sagittis arcu curae finibus ridi
                culus aliquam velit lobortis senectus vitae sollicitudin sit consectetuer ultricies rutrum
                parturient pede nisi nascetur habitant netus quisque elementum inceptos nam felis penatibus
                feugiat </p>

        </div>
    )
}

export default ServiceContent