import React, { useContext, useEffect } from 'react'
import HomeNavBar from '../HomeComponent/HomeNavBar'
import MailIcon from '../../SVG/MailIcon'
import { HrStorage } from '../../Context/HrStore'

const HrSection1 = () => {
    let { setactive } = useContext(HrStorage)
    useEffect(() => {
        setactive('about')
    }, [])
    return (
        <div className='relative ' >
            <img src={require('../../Assest/hrmback.webp')} className='absolute top-0 ' alt="Background" />
            <HomeNavBar type='white' />
            <main className='relative py-5 poppins ' >
                <h5 className='text-white text-2xl w-4/5 sm:text-6xl mx-auto  text-center fw-semibold ' >
                    The Fastest Way To <br /> Automate Your HR Process </h5>
                <p className='w-4/5 mx-auto text-sm sm:text-base text-slate-300 text-center ' >Equals is the only spreadsheet with built-in connections
                    to any <br /> database, versioning, and collaboration. </p>
                <section className='mx-auto shadow w-fit bg-white rounded-full ' >
                    <div className='flex items-center gap-2 p-2 ps-4 ' >
                        <MailIcon />
                        <input type="text" className='outline-none w-40 ' placeholder='Enter your mail' />
                        <button className='px-3 btngrd text-white rounded-full p-3 w-[10rem] h-full
                        ' >
                            Start for free
                        </button>
                    </div>
                </section>
            </main>
            <img src={require('../../Assest/hrdash.webp')} alt="HrDashboard" className='relative px-0 container rounded-xl my-10 bg-red-500 ' />

        </div>
    )
}

export default HrSection1