import React from 'react'
import LocationIcon from '../../SVG/LocationIcon'
import ClockIcon from '../../SVG/ClockIcon'
import CurrencyIcon from '../../SVG/CurrencyIcon'
import CalenderIcon from '../../SVG/CalenderIcon'

const JobCard = () => {
    return (
        <div>
            <main className='flex gap-3 my-2 p-4 bg-white shadow-sm rounded ' >
                <img src={require('../../Assest/jobIcon.png')} alt="Icon" className='w-16 h-fit ' loading='lazy' />
                <section>
                    <p className='mb-1' > Linear company</p>
                    <h4>Software Engineer </h4>
                    <article className='flex gap-3 text-xs items-center ' >
                        <div className='flex items-center gap-1  ' >
                            <LocationIcon size={12} />
                            Brossel
                        </div>
                        <div className='flex items-center gap-1  ' >
                            <ClockIcon size={12} />
                            Full time
                        </div>
                        <div className='flex items-center gap-1  ' >
                            <CurrencyIcon size={15} />
                            23k - 30k
                        </div>
                        <div className='flex items-center gap-1  ' >
                            <CalenderIcon size={12} />
                            24th Apr
                        </div>
                    </article>
                    <p className='my-2 text-sm ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam vel blanditiis tempora accusamus hic consectetur quisquam
                        vero adipisci, illum itaque? </p>
                </section>
            </main>

        </div>
    )
}

export default JobCard