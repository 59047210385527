import React from 'react'
import SearchBarSection from '../Component/CareersComponent/SearchBarSection'
import Explore from '../Component/CareersComponent/Explore'
import JobSection from '../Component/CareersComponent/JobSection'
import Footer from '../Component/Footer'

const CareersPage = () => {
    return (
        <div>
            <SearchBarSection />
            {/* <section className='cont w-full flex flex-col gap-3 ' >
                <div className='div1 ' >

                </div>
                <div className='div2 ' >

                </div>
                <div className='div3' >

                </div>
            </section> */}
            <Explore />
            <JobSection />
        </div>
    )
}

export default CareersPage