import React, { useState } from 'react'
import TruckIcon from '../../SVG/TruckIcon'
import HRMCard from '../Cards/HRMCard'
import MountainIcon from '../../SVG/MountainIcon'
import CampIcon from '../../SVG/CampIcon'
import TentIcon from '../../SVG/TentIcon'
import AdventureIcon from '../../SVG/AdventureIcon'
import CalenderIcon from '../../SVG/CalenderIcon'
import CalenderIcon2 from '../../SVG/CalenderIcon2'
const WhyHrmsNeed = () => {
    let [hoverIndex, setIndex] = useState()
    let cards = [
        {
            size: 120,
            heading: "Adventure",
            icon: TruckIcon,
            para: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis porro illum dolore. Quo aliquam commodi nesciunt,
                            voluptatum architecto iusto repellat.`,
        },
        {
            size: 110,

            heading: "Camping",
            icon: CampIcon,
            para: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis porro illum dolore. Quo aliquam commodi nesciunt,
                            voluptatum architecto iusto repellat.`,
        },
        {
            size: 110,

            heading: "Trekking",
            icon: MountainIcon,
            para: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis porro illum dolore. Quo aliquam commodi nesciunt,
                            voluptatum architecto iusto repellat.`,
        },
        {
            size: 120,

            heading: "Off Road",
            icon: TentIcon,
            para: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis porro illum dolore. Quo aliquam commodi nesciunt,
                            voluptatum architecto iusto repellat.`,
        },
        {
            size: 120,

            heading: "Camp Fire",
            icon: AdventureIcon,
            para: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis porro illum dolore. Quo aliquam commodi nesciunt,
                            voluptatum architecto iusto repellat.`,
        },
        {
            size: 110,

            heading: "Expiring",
            icon: CalenderIcon2,
            para: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis porro illum dolore. Quo aliquam commodi nesciunt,
                            voluptatum architecto iusto repellat.`,
        }

    ]
    return (
        <div className='poppins ' >
            <h4 className='text-center text-3xl sm:text-4xl fw-semibold ' >Why
                <br /> HRMS Is Important  </h4>
            <section className='relative min-h-[60vh]  ' >
                <img src={require('../../Assest/backgroundMoon.png')} alt="background" className='absolute top-0  ' />
                {/* Cards */}
                <article
                    className='flex relative gap-3 py-5 flex-wrap justify-around
                     sm:justify-between container' >
                    {
                        cards.map((obj, index) => (
                            <HRMCard size={obj.size} Child={obj.icon} setIndex={setIndex}
                                index={index} heading={obj.heading} para={obj.para}
                                hoverIndex={hoverIndex} />
                        ))
                    }
                </article>
            </section>

        </div>
    )
}

export default WhyHrmsNeed