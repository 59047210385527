import React from 'react'

const WhyChooseUs = () => {
    return (
        <div className='servicebg xl:min-h-[40vh] relative text-slate-50 poppins ' >
            <main className='row relative z-10 container mx-auto ' >
                <section className='col-lg-6 ' >
                    <p className='text-blue-600 ' >About US </p>
                    <p className='text-2xl sm:text-5xl xl:text-6xl michroma text-slate-50 '>
                        Why Choose <br /> US </p>
                </section>
                <section className='col-lg-6 fw-light tracking-wider ' >
                    <p> Computer glasses with AI technology combine cameras and sensors to provide contextual information and personal assistance. Recognizing objects, faces, and emotions, these glasses facilitate navigation, schedule reminders, and enhance productivity. Stylish design and artificial intelligence make them an elegant
                        and functional wearable device.</p>
                    <p className='my-4 ' > Computer glasses with AI technology combine cameras and sensors to provide contextual information and personal assistance. Recognizing objects, faces, and emotions, these glasses facilitate navigation, schedule reminders, and enhance productivity. Stylish design and artificial intelligence make them an elegant
                        and functional wearable device.</p>
                    <div className='flex flex-wrap gap-20 ' >
                        <p>
                            <span className='text-2xl michroma sm:text-3xl ' >
                                150k+
                            </span> <br />
                            Service Center
                        </p>
                        <p>
                            <span className='text-2xl michroma sm:text-3xl ' >
                                180k+
                            </span> <br />
                            AI Programmed
                        </p>

                    </div>
                </section>
            </main>
            <main className='container relative z-10 mx-auto row mt-5 ' >
                <section className='col-lg-6 ' >
                    <h4 className='text-3xl sm:text-5xl ' >Easy collaborative
                        financial team </h4>
                    <p className='text-sm w-10/12  ' >No need to worry about your files being lost because we are very loyal to be your storage platform. No need to worry about your files being lost because we are very loyal to be your storage platform. No need to worry about your files being lost because we are very loyal to be your storage platform.No need to worry about your
                        files being lost because we .</p>
                </section>
                <section className='col-lg-6 py-5   ' >
                    <img className='rounded-xl bg-red-400 ' src={require('../../Assest/DashboardLight.webp')} alt="DashBoard" />

                </section>

            </main>
            <img className='absolute w-[24rem] z-0 right-0 -bottom-32 ' src={require('../../Assest/ecplise.webp')} alt="Ecplise" />

        </div>
    )
}

export default WhyChooseUs