import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import HomePage from './Pages/HomePage';
import CareersPage from './Pages/CareersPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Css/careers.css'
import './Css/home.css'
import './Css/hrsection.css'
import './Css/service.css'
import Footer from './Component/Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactPage from './Pages/ContactPage';
import HrmsPublish from './Pages/HrmsPublish';
import ServicePage from './Pages/ServicePage';
import SectorsPage from './Pages/SectorsPage';

export const domain = 'https://backendapi.meridahr.com'
function App() {

  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/careers' element={<CareersPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/about' element={<HrmsPublish />} />
          <Route path='/service/*' element={<ServicePage />} />
          <Route path='/service/:id' element={<ServicePage />} />
          <Route path='/industries/:id' element={<SectorsPage />} />
        </Routes>
        <Footer />

      </BrowserRouter>

    </div>
  );
}

export default App;
