import React from 'react'
import MessageIcon from '../../SVG/MessageIcon'
import TagIcon from '../../SVG/TagIcon'

const ServiceBlogCard = () => {
    return (
        <div>
            {/* card */}
            <div className='rounded-xl mb-3 border-1 border-slate-200 
                     h-fit p-2 flex gap-2 ' >
                <div className='w-[40%]  relative' >
                    <img src={require('../../Assest/DashboardLight.webp')} alt="DashBoard"
                        className='block  rounded-xl ' />
                    <div className='bgindigo absolute rounded top-2 left-2
                            text-white text-center text-xs p-[2px] w-9 h-9 ' >
                        20 <br />
                        <span>Dec </span>
                    </div>
                </div>
                <article className='p-3 flex-1 ' >
                    <div className='poppins text-sm flex gap-2 ' >
                        <p className='flex gap-1 items-center mb-0 ' > <span className='text-violet-900 ' >
                            <TagIcon size={14} /> </span>
                            Technology </p>
                        <p className='flex gap-1 items-center mb-0 '> <span className='text-violet-900 ' >
                            <MessageIcon size={14} /> </span>
                            Technology </p>
                    </div>
                    <p className=' line-clamp-2 text-xl fw-semibold ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium nemo doloremque et
                        beatae deserunt possimus, maxime at nostrum dolore ea? </p>
                </article>
            </div>
        </div>
    )
}

export default ServiceBlogCard