import React, { useState } from 'react'
import RightArrow from '../../SVG/RightArrow'
import Pen from '../../SVG/Pen'

const Explore = () => {
    let [selectedId, setSelectedId] = useState()
    return (
        <div className='container poppins mx-auto min-h-[30vh] py-4 ' >
            <section className='flex items-center justify-between ' >
                <h4 className=' text-xl sm:text-3xl xl:text-4xl ' >Explore by category </h4>
                <button className='text-blue-600 flex items-center gap-2 text-sm fw-semibold ' >
                    Show all jobs
                    <RightArrow size={18} />
                </button>

            </section>

            <section className='flex gap-3 justify-between flex-wrap my-3' >

                {
                    [1, 2, 3, 4, 5, 6, 7, 8].map((val, index) => (
                        <article onMouseEnter={() => setSelectedId(index)}
                            onMouseLeave={() => setSelectedId(-1)}
                            className={` ${selectedId == index && 'bg-indigo-700 text-white '} duration-500 
                            cursor-pointer p-3 border-2 w-[16rem] `} >
                            <span className={` ${selectedId == index ? 'text-slate-50' : 'text-violet-700'}  `} >
                                <Pen size={30} />
                            </span>
                            <h5 className='my-2' >Design </h5>
                            <div className='flex items-center justify-between text-sm' >
                                <p className='m-0'>235 job available </p>
                                <RightArrow size={20} />
                            </div>
                        </article>
                    ))
                }

            </section>


        </div>
    )
}

export default Explore