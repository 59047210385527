import React from 'react'
import RightArrow from '../../SVG/RightArrow'
import Slider from 'react-slick'
import HomeServiceCard from '../Cards/HomeServiceCard'

const OurServices = () => {
    let settings = {
        dots: false,
        arrows: false,
        speed: 2000,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        responsive: [
            {
                breakpoint: 1040,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 830,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <div className='servicebg py-5' >
            <main className='row container mx-auto poppins ' >
                <section className='col-lg-3 ' >
                    <p className='text-blue-600 ' >Our Services </p>
                    <p className='text-2xl sm:text-4xl text-white ' >Look Into our Beloved Sectors </p>
                    <button className='bg-blue-600 flex gap-2 items-center text-white rounded p-2 ' >
                        See All
                        <RightArrow size={20} />
                    </button>

                </section>
                <section className='col-lg-9 ' >
                    {/* 1 */}
                    <main className='container my-3 ' >
                        <Slider {...settings} >
                            <HomeServiceCard />
                            <HomeServiceCard bg='bg-white' />
                            <HomeServiceCard bg='bg-slate-600' br='border-slate-50' cir='bg-white'
                                txt='text-slate-50' />
                        </Slider>


                    </main>
                    {/* 2 */}
                    <main className='container my-3 ' >
                        <Slider {...settings} >
                            <HomeServiceCard />
                            <HomeServiceCard bg='bg-white' />
                            <HomeServiceCard bg='bg-slate-600' br='border-slate-50' cir='bg-white'
                                txt='text-slate-50' />
                        </Slider>


                    </main>



                </section>

            </main>
        </div>
    )
}

export default OurServices