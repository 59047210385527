import React from 'react'

const HrSection = () => {
    return (
        <div style={{ backgroundColor: '#F6F5FF' }} className=' my-0' >
            <main className='row container my-0 mx-auto py-5' >
                <section className='col-lg-6 my-5 p-3 flex' >
                    <div className='m-auto ' >
                        <h4 className='fw-semibold text-3xl sm:text-5xl' >
                            Powerful and easy to <br /> use UI </h4>
                        <p className='w-4/5 ' >Use marketing automation to identify hot leads and email your sales team telling them to follow up. Nurture and contact leads at the
                            opportune time to maximize. </p>
                        <button className='p-2 uppercase fw-semibold  rounded-full btngrd text-white px-4 ' >
                            Get Started
                        </button>
                    </div>
                </section>
                <section className='col-lg-6 my-5 p-3 flex' >
                    <div className='m-auto btngrd rounded-xl relative h-full w-[18rem] ' >
                        <img src={require('../../Assest/man.webp')} className='absolute bottom-0 w-[18rem] right-0  ' alt="Man" />
                        <img src={require('../../Assest/strategy.webp')} className='absolute bottom-0 -left-5 rounded-xl w-36 ' alt="Strategy" />
                    </div>
                </section>

            </main>
        </div>
    )
}

export default HrSection