import React, { useContext, useEffect, useState } from 'react'
import BannerCom from '../Component/ServiceComponent/BannerCom'
import WorkProcess from '../Component/ServiceComponent/WorkProcess'
import LatestNews from '../Component/ServiceComponent/LatestNews'
import OurBlogs from '../Component/HomeComponent/OurBlogs'
import ServiceWorkProcess from '../Component/ServiceComponent/ServiceWorkProcess'
import ServiceContent from '../Component/ServiceComponent/ServiceContent'
import { HrStorage } from '../Context/HrStore'
import { serviceContent } from '../Component/Data'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const ServicePage = () => {
    let { setactive } = useContext(HrStorage)
    let { sid } = useParams()
    let [content, setcontent] = useState()

    useEffect(() => {
        setactive('service')
        serviceContent.forEach((content) => {
            if (content.SID == sid)
                setcontent(content)
        })
    }, [sid])
    return (
        <div>
            {content &&
                <Helmet>
                    <title> {content.SeoTitle}</title>
                    <meta name="description" content={`${content.SeoDescription}`} />
                    <link rel="canonical" href={content.SeoCanonical} />
                </Helmet>
            }
            <BannerCom />

            <WorkProcess />
            <ServiceContent />
            <LatestNews />

            <ServiceWorkProcess />
            <OurBlogs />
        </div>
    )
}

export default ServicePage