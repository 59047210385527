import React, { createContext, useState } from 'react'


export const HrStorage = createContext()
const HrStore = ({ children }) => {
    let [active, setactive] = useState("")

    let a = 'hellow'
    let value = { a, active, setactive }
    return (
        <HrStorage.Provider value={value} >
            {children}
        </HrStorage.Provider>
    )
}

export default HrStore