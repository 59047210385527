import React from 'react'

const EventsSection = () => {
    return (
        <div className='bgevent' >
            <main className='container mx-auto ' >


                <p className='text-blue-700 fw-bold mb-2 mt-5 ' >Events </p>
                <p className='text-2xl sm:text-4xl michroma '>
                    Some words <br /> from our clients </p>
            </main>
            <section className='container mx-auto    ' >
                {/* <img src={require('../../Assest/bgtech.webp')} alt="BgTech" className='z-0 absolute' /> */}
                <main className='py-4 row justify-around ' >

                    <div className='col-sm-6 p-3 ' >
                        <div className='p-3 flex gap-3 shadow bg-white rounded ' >
                            <img src={require('../../Assest/DashboardLight.webp')} alt="Image" className='w-[7rem] h-[7rem] object-cover  ' />
                            <div className='' >
                                <h5>Jerold </h5>
                                <p className='text-teal-400 fw-semibold mb-1' >Developer </p>
                                <p className='text-slate-500 text-sm ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, veniam? </p>
                            </div>
                             </div>
                    </div>

                    <div className='col-sm-6 p-3 ' >
                        <div className='p-3 flex gap-3 shadow bg-white rounded ' >
                            <img src={require('../../Assest/DashboardLight.webp')} alt="Image" className='w-[7rem] h-[7rem] object-cover  ' />
                            <div className='' >
                                <h5>Jerold </h5>
                                <p className='text-teal-400 fw-semibold mb-1' >Developer </p>
                                <p className='text-slate-500 text-sm ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, veniam? </p>
                            </div>
                             </div>
                    </div>
                    <div className='col-sm-6 p-3 ' >
                        <div className='p-3 flex gap-3 shadow bg-white rounded ' >
                            <img src={require('../../Assest/DashboardLight.webp')} alt="Image" className='w-[7rem] h-[7rem] object-cover  ' />
                            <div className='' >
                                <h5>Jerold </h5>
                                <p className='text-teal-400 fw-semibold mb-1' >Developer </p>
                                <p className='text-slate-500 text-sm ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, veniam? </p>
                            </div>
                             </div>
                    </div>
                    <div className='col-sm-6 p-3 ' >
                        <div className='p-3 flex gap-3 shadow bg-white rounded ' >
                            <img src={require('../../Assest/DashboardLight.webp')} alt="Image" className='w-[7rem] h-[7rem] object-cover  ' />
                            <div className='' >
                                <h5>Jerold </h5>
                                <p className='text-teal-400 fw-semibold mb-1' >Developer </p>
                                <p className='text-slate-500 text-sm ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, veniam? </p>
                            </div>
                             </div>
                    </div>
                </main>


            </section>

        </div>
    )
}

export default EventsSection