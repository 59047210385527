import React from 'react'
import ArrowRght from '../../SVG/ArrowRght'
import RightArrow from '../../SVG/RightArrow'
import TagIcon from '../../SVG/TagIcon'
import MessageIcon from '../../SVG/MessageIcon'
import ServiceBlogCard from '../Cards/ServiceBlogCard'

const LatestNews = () => {
    return (
        <div className='my-5 xl:min-h-[80vh] ' >
            <main className='container my-3 mx-auto poppins ' >
                <section className='flex justify-between items-center ' >
                    <div>
                        <h5 className='uppercase textgrdservice text-lg ' >Latest news </h5>
                        <p className='text-2xl sm:text-4xl fw-semibold ' > Latest News <span className='text-blue-900 ' >&</span> Blog </p>
                    </div>
                    <div className='flex ' >
                        <button className='uppercase rounded-full p-3 px-4 text-blue-800 bg-sky-50 fw-semibold  ' >
                            View All news
                        </button>
                        <button className='w-10 h-10 flex items-center justify-center -rotate-45 rounded-full bg-blue-900 text-white  ' >
                            <RightArrow />
                        </button>
                    </div>
                </section>
            </main>
            <main className=' container mx-auto my-3 row ' >
                <section className='col-md-6 relative rounded p-4 min-h-[50vh] bg-slate-200 ' >
                    {/* date */}

                    <div className='bgindigo absolute
                     rounded text-white text-center text-sm p-1 w-12 h-12 ' >
                        20 <br />
                        <span>Dec </span>
                    </div>
                    {/* Heading */}
                    <div className='absolute bottom-0 ' >
                        <div className='poppins text-sm flex gap-2 ' >
                            <p className='flex gap-1 items-center mb-0 ' > <TagIcon />Technology </p>
                            <p className='flex gap-1 items-center mb-0 '> <MessageIcon />Technology </p>
                        </div>
                        <p className='line-clamp-2 fw-semibold text-2xl w-4/5 '  >Keep Your Business Safe & Ensure High Availability. </p>
                    </div>
                </section>
                <section className='col-md-6' >
                    <ServiceBlogCard />
                    <ServiceBlogCard />
                </section>
            </main>

        </div>
    )
}

export default LatestNews