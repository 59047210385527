import React from 'react'
import SearchIcon from '../../SVG/SearchIcon'

const SearchBarSection = () => {
    return (
        <div className='w-full searchbar p-5 min-h-[30vh] poppins' >
            <h4 className='text-white text-xl sm:text-3xl xl:text-4xl fw-semibold    '>Find your
                <span className='text-violet-800 ' > new job</span> today</h4>
            <p className='my-3 text-sm ' >Hundreds of jobs waiting for you. </p>
            <section className='flex w-full rounded overflow-hidden ' >
                <div className=' p-2 flex-1 flex gap-2 bg-white   text-sm ' >
                    <SearchIcon/>
                    <input type="text" className='w-full outline-none '
                        placeholder='What position are you looking for ?' />
                </div>
                <button className='bg-violet-800 text-white text-sm p-2 px-3 ' >
                    Search job
                </button>
            </section>


        </div>
    )
}

export default SearchBarSection