import React from 'react'

const RightSide = () => {
    return (
        <div>
            {/* Mail */}
            <main className='bg-white rounded border-2 p-3 ' >
                <p className=' fw-semibold text-xl mb-1 ' > ✉️ Email me for jobs </p>
                <p className='text-sm ' >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ut, at? </p>
                <input type="text" placeholder='name@gmail.com'
                    className=' rounded p-2 border-2 outline-none ' />
                <button className='bg-blue-600 rounded w-full my-3 p-2 text-white ' >
                    Send Now
                </button>
            </main>
            <main className='bg-white rounded border-2 p-3 my-3 ' >
                <p className=' fw-semibold text-xl mb-1 ' > 🚀 Get noticed faster </p>
                <p className='text-sm ' >Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ut, at? </p>
                <button className='bg-blue-600 rounded w-full my-3 p-2 text-white ' >
                    Upload your resume
                </button>
            </main>

        </div>
    )
}

export default RightSide