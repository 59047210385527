import React from 'react'

const HRMCard = ({ heading, para,size, index, setIndex, hoverIndex, Child }) => {
    return (
        <div onMouseEnter={() => setIndex(index)}
            onMouseLeave={() => setIndex(-1)}
            className={`flex cursor-pointer duration-500 shadow 
                ${hoverIndex == index ? 'text-slate-50 btngrd ' : 'bg-white text-slate-400'}
 flex-col text-center justify-center items-center rounded w-[18rem]  xl:w-[20rem] p-4 `} >
            <Child size={size} /> 
            <h5 className=' text-xl my-3 fw-semibold' >{heading} </h5>
            <p className='text-sm ' > Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis porro illum dolore. Quo aliquam commodi nesciunt,
                voluptatum architecto iusto repellat.
            </p>
        </div>
    )
}

export default HRMCard