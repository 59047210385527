import React from 'react'
import LeafIcon from '../../SVG/LeafIcon'
import ArrowRght from '../../SVG/ArrowRght'
import RightArrow from '../../SVG/RightArrow'
import TargetIcon from '../../SVG/TargetIcon'
import LockIcon from '../../SVG/LockIcon'
import BellIcon from '../../SVG/BellIcon'

const BoostProductivity = () => {
    return (
        <div className='poppins text-slate-800 relative my-0 hrbggrd' >
            <img src={require('../../Assest/toruscircle.webp')} alt="Circle" className='absolute bottom-1/4 left-0 w-28 ' />
            <img src={require('../../Assest/diamond.webp')} alt="Diamond" className='absolute top-1/4 right-0 w-24 ' />
            <p className=' text-center fw-semibold text-xl w-fit mx-auto rounded-2xl p-2 px-4 border-2 ' >Boost your productivity </p>
            <h5 className='text-center text-3xl sm:text-5xl' >A more effective way <br /> to track employee </h5>
            <p className='text-center w-[400px] mx-auto text-sm ' >Effortlessly turn your ideas into a fully functional, responsive, no-code SaaS website in just minutes with the set of free components
                for Framer. </p>
            <img src={require('../../Assest/dashboardImage.webp')} alt="Image"
                className='container my-5 px-0 rounded-xl mx-auto ' />
            <section className='flex flex-wrap container mx-auto py-4 justify-between' >
                {/* Card 1 */}
                <div className='col-6 col-lg-3 p-2 ' >
                    <LeafIcon size={20} />
                    <h5 className=' my-2' >Integration ecosystem </h5>
                    <p className=' text-sm' >Track your progress and motivate your efforts everyday. </p>
                    <button className='flex gap-2 items-center ' >
                        Learn more <RightArrow size={16} />
                    </button>
                </div>
                {/* Card 2 */}
                <div className='col-6 col-lg-3 p-2 ' >
                    <TargetIcon size={20} />
                    <h5 className=' my-2' >Goal setting and tracking</h5>
                    <p className=' text-sm' >Set and track goals with manageable task breakdowns. </p>
                    <button className='flex gap-2 items-center ' >
                        Learn more <RightArrow size={16} />
                    </button>
                </div>
                {/* card 3 */}
                <div className='col-6 col-lg-3 p-2 ' >
                    <LockIcon size={20} />
                    <h5 className=' my-2' >Secure data encryption </h5>
                    <p className=' text-sm' >Track your progress and motivate your efforts everyday. </p>
                    <button className='flex gap-2 items-center ' >
                        Learn more <RightArrow size={16} />
                    </button>
                </div>
                {/* Card 4 */}
                <div className='col-6 col-lg-3 p-2 ' >
                    <BellIcon size={20} />
                    <h5 className=' my-2' >Customizable notifications </h5>
                    <p className=' text-sm' >Get alerts on tasks and deadlines that matter most.</p>
                    <button className='flex gap-2 items-center ' >
                        Learn more <RightArrow size={16} />
                    </button>
                </div>
            </section>
            <p className='h-[50px]  ' ></p>
        </div>
    )
}

export default BoostProductivity
