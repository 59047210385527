import React from 'react'

let serviceContent = [
    {
        "id": 1,
        "SID": "RPO",
        SeoTitle: `Efficient RPO Solutions`,
        SeoDescription: `Discover efficient RPO solutions tailored to your needs. Streamline recruitment processes, save time, and find top talent effortlessly with our expertise.`,
        SeoCanonical: `https://meridahr.com/service/RPO`,
        "icon": "../Assest/human-resources.png",
        "title": "Recruitment Process Outsourcing (RPO)",
        "heading1": "Unlock the Power of Strategic Hiring with Merida HR Consulting",
        "para1": `Are you in search of a trustworthy and capable way to make your recruiting work better? Well, Merida HR Consulting is here for you. If you are looking for `,
        link: `Recruitment Process Outsourcing `,
        alink: "https://en.wikipedia.org/wiki/Recruitment#Recruitment_Process_Outsourcing",
        para11: `(RPO) then look no further. Merida HR Consulting could change how you build your workforce if only given an opportunity.
        `,
        // "heading2": "Why Choose Merida HR Consulting for RPO?",
        "headin2content": [
            {
                "title": "End-to-End Recruitment Management",
                "content": `We manage the entire recruitment process
from job requisition to candidate on-boarding, allowing clients to focus on their core
business activities. This includes crafting job descriptions, sourcing candidates,
conducting interviews, and facilitating offers.`
            },
            {
                "title": "Tailored Recruitment Strategies:",
                "content": `Our approach is customized to align with each
client’s specific needs, industry requirements, and organizational culture. We develop
and implement targeted recruitment strategies to attract top talent.`
            },
            {
                "title": "Access to Advanced Technology:",
                "content": `We utilize state-of-the-art recruitment
technologies and tools to enhance sourcing efficiency, candidate screening, and
overall process management. Our technology stack includes applicant tracking
systems (ATS) and advanced analytics.`
            },
            {
                "title": "Expert Talent Acquisition Team:",
                "content": `Our dedicated team of recruitment professionals
brings extensive industry expertise and insights to ensure high-quality candidate
sourcing and selection. We leverage our network and knowledge to find the best
candidates.`
            },
            {
                "title": "Streamlined Candidate Experience:",
                "content": `We ensure a smooth and engaging experience
for candidates throughout the recruitment process. From initial contact to on-
boarding, we manage communication and provide timely updates to keep candidates
informed.` }
        ],
        "heading3": "How Merida HR Consulting RPO Works",
        "heading3content": [
            {
                "title": "Scalable Solutions:",
                "content": `Our RPO services are designed to scale with your business
needs, whether you require support for high-volume hiring, specialized roles, or
ongoing recruitment needs. We adapt our approach to match the evolving demands of
your organization.`
            },
            {
                "title": "Cost Efficiency :",
                'content': `By outsourcing recruitment processes to Merida HR, clients can
reduce recruitment costs and improve cost-per-hire metrics. Our streamlined
processes and economies of scale contribute to overall cost savings.`
            },
            {
                "title": "Enhanced Compliance and Risk Management:",
                "content": `We ensure compliance with all
relevant employment laws and regulations, mitigating risks associated with
recruitment practices. Our rigorous processes and expertise help maintain legal and
ethical standards.`
            },
            {
                "title": "Data-Driven Insights:",
                "content": `We provide clients with detailed reports and analytics on
recruitment metrics, including time-to-fill, cost-per-hire, and candidate quality. These
insights enable informed decision-making and continuous improvement.`  },
            {
                "title": "Strategic Workforce Planning:",
                "content": `We collaborate with clients to understand their long-
term workforce needs and develop strategic recruitment plans that align with business
objectives and growth plans.`
            },
            {
                "title": "Continuous Improvement:",
                "content": `We are committed to ongoing process optimization and
innovation. We regularly review and refine our recruitment strategies to enhance
efficiency and effectiveness.`
            },
        ],
        // "heading4": "Experience the Merida HR Consulting Difference Today!",
        // "heading4content": "Don't let recruitment challenges hold your business back. Partner with Merida HR Consulting for a reliable, efficient, and cost-effective Recruitment Process Outsourcing solution. Contact us today to schedule a consultation and take the first step towards building a high-performing team that drives your success.",
        "img1": "../Assest/rpo1.jpeg",
        "img2": "../Assest/rpo2.jpg",
        "img3": "../Assest/rpo3.jpg"
    },
    {
        "id": 2,
        "SID": "Contract-Staffing-Solutions",
        "title": "Contract Staffing Solutions",
        SeoTitle: `Contract Staffing Solutions`,
        SeoDescription: `Explore our contract staffing solutions for flexible workforce needs. We offer tailored services to match your requirements and streamline hiring processes effectively.`,
        SeoCanonical: `https://meridahr.com/service/Contract-Staffing-Solutions`,
        "icon": "../Assest/contract.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Trusted Partner in Contract Staffing",
        "para1": `At  Merida HR Consulting, we understand the dynamic nature of today's business environment. That's why we offer cutting-edge Contract`,
        link: ` Staffing `,
        alink: "https://en.wikipedia.org/wiki/Staffing",
        para11: `solutions designed to meet your immediate staffing needs with flexibility and efficiency.
        `,
        "heading2": "Why Choose  Merida HR Consulting for Contract Staffing?",
        "headin2content": [
            {
                "title": "Customized Staffing Plans :",
                "content": `We develop tailored staffing plans to meet each client&#39;s
specific needs, including short-term projects, seasonal demands, or specialized skill
requirements.`
            },
            {
                "title": "Comprehensive Talent Sourcing:",
                "content": `Our extensive network and advanced sourcing
techniques enable us to quickly identify and recruit top-tier contract professionals
who match the precise requirements of the role and your organization.` },
            {
                "title": "Rigorous Candidate Screening:",
                "content": `We conduct thorough screening processes,
including skills assessments, background checks, and reference verifications, to
ensure that contract candidates meet the highest standards of competency and
reliability.`
            },
            {
                "title": "Flexible Staffing Models :",
                "content": `We offer flexible staffing solutions to adapt to varying
contract durations, from short-term assignments to long-term projects, ensuring
alignment with your business needs and project timelines.` },
            {
                "title": "Efficient On-Boarding :",
                "content": `Our streamlined on-boarding process ensures that contract
employees are integrated smoothly and swiftly into your organization, minimizing
downtime and maximizing productivity.` }
        ],
        // "heading3": "How  Merida HR Consulting Contract Staffing Works:",
        "heading3content": [
            {
                "title": "Compliance and Risk Management:",
                "content": `We manage all compliance-related aspects,
including employment laws, tax regulations, and contract terms, reducing your
administrative burden and mitigating legal risks.`
            },
            {
                "title": "Performance Management:",
                'content': `We provide ongoing support and performance
monitoring for contract staff to ensure they meet your expectations and project
requirements. Regular check-ins and feedback mechanisms are part of our service to
ensure optimal performance.` },
            {
                "title": "Cost Control and Transparency:",
                "content": `Our contract staffing solutions offer cost-effective
pricing models and clear transparency regarding fees and charges, allowing you to
manage your budget effectively.`  },
            {
                "title": "Dedicated Account Management:",
                "content": `A dedicated account manager will be assigned to
your organization to provide personalized support, address any issues promptly, and
ensure that your contract staffing needs are consistently met.`
            },
            {
                "title": "Scalable Solutions:",
                'content': `Our contract staffing services are designed to scale with your
changing needs, whether you require additional staff for sudden increases in workload
or specialized talent for specific projects.` },
            {
                "title": "Cost Control and Transparency:",
                "content": `We work closely with you to understand your
strategic goals and workforce planning needs, ensuring that our contract staffing
solutions align with your long-term business objectives.`  },
            {
                "title": "Rapid Response and Placement:",
                "content": `We prioritize quick response times and efficient
placement processes to ensure that you have the necessary talent in place as soon as
possible to meet project demands and deadlines.`
            }
        ],
        // "heading4": "Experience the  Merida HR Consulting Advantage Today!",
        // "heading4content": "Ready to build your workforce with flexible, skilled professionals? Partner with  Merida HR Consulting for Contract Staffing solutions that drive efficiency, innovation, and success. Contact us today to discuss your staffing needs and take the first step towards building a dynamic and high-performing team.",
        "img1": "../Assest/ContractStaffingSolutions1.jpeg",
        "img2": "../Assest/ContractStaffingSolutions2.jpeg",
        "img3": "../Assest/ContractStaffingSolutions3.jpeg"
    },
    {
        "id": 3,
        "SID": "Permanent-Staffing",
        SeoTitle: `Permanent Staffing Solutions: Find Your Ideal Team`,
        SeoDescription: `Secure long-term success with our permanent staffing solutions. We connect you with top talent to build a thriving team.`,
        SeoCanonical: `https://meridahr.com/service/Permanent-Staffing`,
        "title": "Permanent Staffing",
        "icon": "../Assest/profile.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Premier Partner in Permanent Staffing",
        "para1": `Merida HR’s permanent staffing services for clients involve managing the entire recruitment
process to find and place employees who are intended to be a long-term part of the client's
organization. Here’s an overview of how Merida HR might approach permanent staffing for
clients`,
        // link: ` Permanent Staffing `,    
        // alink: "https://en.wikipedia.org/wiki/Permanent_staff_instructor",
        // para11: `solutions are crafted to connect you with top-tier talent that aligns seamlessly with your company's vision and goals.
        // `,
        // "heading2": "Why Choose  Merida HR Consulting for Permanent Staffing?",
        "headin2content": [
            {
                "title": "Understanding Client Needs",
                "content": [`Initial Consultation: Merida HR begins by engaging with the client to understand
their specific needs, company culture, and the roles they are looking to fill.`,
                    `Job Analysis: Conducting a detailed job analysis to define the skills, experience, and
qualifications required for the position.`]
            },
            {
                "title": "Recruitment Strategy",
                "content": [`Sourcing Candidates: Utilizing various channels to attract potential candidates,
including job boards, social media, industry networks, and internal databases.`,
                    `Talent Pool: Building and maintaining a database of pre-screened candidates who
match the client’s requirements.`]
            },
            {
                "title": "Screening and Selection",
                "content": [`Resume Screening: Reviewing resumes and applications to shortlist candidates who
meet the job criteria.`,
                    `Interviews: Conducting preliminary interviews to assess candidates’ skills,
experience, and cultural fit.`,
                    `Assessment: Performing additional assessments, such as skills tests or personality
assessments, if required.`]
            },
            {
                "title": "Candidate Presentation",
                "content": [`Shortlist: Presenting a shortlist of the most suitable candidates to the client, along
with detailed profiles and assessment results.`,
                    `Coordination: Scheduling interviews between the client and candidates and
managing the interview process.`,]
            },

        ],
        // "heading3": "How  Merida HR Consulting Permanent Staffing Works:",
        "heading3content": [
            {
                "title": "Offer Management",
                "content": [`Negotiation: Assisting in negotiating job offers, including salary, benefits, and other
employment terms.`,
                    `Offer Letter: Preparing and presenting the offer letter to the selected candidate and
ensuring all paperwork is completed.`,]
            },
            {
                "title": "On-Boarding",
                'content': [`Transition Support: Helping with the on-boarding process to ensure a smooth
transition for the new hire into the client&#39;s organization.`,
                    `Integration: Providing support to integrate the new employee into the team and the
company culture.`,]
            },
            {
                "title": "Follow-Up",
                'content': [`Post-Hire Support: Checking in with both the client and the new hire to address any
issues and ensure satisfaction on both sides.`,
                    `Feedback: Gathering feedback from the client to improve future recruitment
processes and services.`,]
            },
            {
                "title": "Benefits of Using Merida HR’s Permanent Staffing Services",
                "content": [`Expertise: Leveraging Merida HR’s expertise in recruitment to find high-quality
candidates efficiently.`,
                    `Time-Saving: Reducing the time and effort required by the client’s HR team to
manage the recruitment process.`,
                    `Quality Candidates: Access to a broad network of qualified candidates who might
not be accessible through traditional channels.`,
                    `Process Efficiency: Streamlined recruitment processes that ensure a quicker and more
effective hiring process.`]
            }
        ],
        // "heading4": "Partner with  Merida HR Consulting for Exceptional Permanent Staffing Solutions",
        // "heading4content": "Ready to build a lasting, high-performing team? Choose  Merida HR Consulting as your trusted partner for Permanent Staffing solutions. Contact us today to discuss your hiring needs and embark on a journey to secure top-tier talent that drives your business forward.",
        "img1": "../Assest/PermanentStaffing1.jpeg",
        "img2": "../Assest/PermanentStaffing2.jpeg",
        "img3": "../Assest/PermanentStaffing3.jpeg"
    },
    {
        "id": 4,
        "SID": "Payroll-Processing",
        SeoTitle: `Payroll Processing Made Easy: Trusted Solutions`,
        SeoDescription: `Secure long-term success with our permanent staffing solutions. We connect you with top talent to build a thriving team.`,
        SeoCanonical: `https://meridahr.com/service/Payroll-Processing`,
        "title": "Payroll Processing",
        "icon": "../Assest/salary.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Reliable Partner in Seamless Payroll Processing",
        "para1": `At  Merida HR Consulting, we understand the critical role that payroll plays in your business. Our comprehensive `,
        link: ` Payroll `,
        alink: "https://en.wikipedia.org/wiki/Payroll",
        para11: `Processing services are designed to take the burden off your shoulders, ensuring accuracy, compliance, and efficiency in managing your payroll functions.
        `,
        "heading2": "Why Choose  Merida HR Consulting for Payroll Processing?",
        "headin2content": [
            {
                "title": "End-to-End Payroll Management:",
                "content": `We handle the entire payroll process, including
calculation of wages, deductions, and bonuses, ensuring accurate and timely payments
to employees.` },
            {
                "title": "Compliance Assurance:",
                "content": `Our payroll services ensure compliance with all relevant tax
laws, labor regulations, and employment standards. We stay up-to-date with changes
in legislation to manage tax withholdings and filings effectively.`},
            {
                "title": "Automated Payroll Processing:",
                "content": `We utilize advanced payroll software to automate
and streamline payroll calculations, reducing errors and improving efficiency in
payroll processing.` },
            {
                "title": "Customized Payroll Solutions:",
                "content": `Our services are tailored to meet the specific needs
of your organization, including different pay structures, frequency of pay periods, and
various employee classifications.`
            },
            {
                "title": "Direct Deposit and Pay-check Distribution:",
                "content": `We offer direct deposit services for
employee convenience, along with options for physical pay-check distribution as
required.`}
        ],
        // "heading3": "How  Merida HR Consulting Payroll Processing Works:",
        "heading3content": [
            {
                "title": "Tax Filing and Reporting:",
                "content": `We manage all aspects of tax filing, including federal,
state, and local taxes. Our comprehensive reporting includes necessary forms, such as
W-2s and 1099s, ensuring timely and accurate submissions.`
            },
            {
                "title": "Benefits Administration:",
                'content': `We assist with the administration of employee benefits,
including deductions for health insurance, retirement plans, and other benefits,
integrating these into the payroll process seamlessly.`},
            {
                "title": "Confidentiality and Data Security:",
                "content": `We prioritize the confidentiality and security of
payroll data, employing robust security measures to protect sensitive employee
information and ensure data integrity.` },
            {
                "title": "Employee Self-Service Portal: ",
                "content": `We provide access to an online self-service portal
where employees can view their pay stubs, tax documents, and personal payroll
information, enhancing transparency and convenience.`},
            {
                "title": "Error Resolution and Support:",
                "content": `Our team offers dedicated support to address any
payroll discrepancies or issues promptly, providing clear communication and
resolution to ensure employee satisfaction.`
            },
            {
                "title": "Comprehensive Reporting:",
                'content': `We generate detailed payroll reports that provide
insights into payroll costs, tax liabilities, and other financial metrics, helping you
make informed business decisions.`},
            {
                "title": "Scalable Solutions:",
                "content": `Our payroll services are designed to scale with your business,
accommodating changes in employee headcount, payroll complexity, and
organizational growth.` },
            {
                "title": "Ongoing Consultation: ",
                "content": `We offer ongoing consultation to optimize payroll processes,
review compliance, and adapt to any changes in your business needs or regulatory
environment.`}
        ],
        // "heading4": "Partner with  Merida HR Consulting for Effortless Payroll Processing",
        // "heading4content": "Experience the ease and efficiency of outsourcing your payroll processing to  Merida HR Consulting. Contact us today to explore how our tailored payroll solutions can benefit your business, allowing you to focus on what you do best.",
        "img1": "../Assest/payroll1.jpeg",
        "img2": "../Assest/payroll2.jpeg",
        "img3": "../Assest/payroll3.jpeg"
    }
]

let industrycontent = [
    {
        "id": 1,
        "SID": "information-technology",
        "icon": "../Assest/human-resources.png",
        "title": "Information Technology",
        "heading1": "Unlock the Power of Strategic Hiring with Merida HR Consulting",
        "para1": `When Merida HR caters to IT clients, they would focus on providing tailored staffing solutions that
address the specific needs and challenges of the IT industry. Here’s how Merida HR might structure
its services for IT clients`,
        "para2": `Hospitality industry is a growing industry in India and is going to touch $460 billion by 2028.
        There is an increase in people traveling within India and also foreign visitors coming to visit India.
        `,
        "para3": `Hospitality industry includes hotels and hotel chains, independent hotels and service apartments,budget and economy hotels, mid and upper and  mid scale  hotels and luxury 
        hotels.`,
        "para4": `The hospitality industry is growing and there is demand by people for hotel and    accommodation.This is because people are traveling  more for their jobs and on  business trips and also for entertainment 
        and sightseeing purposes, outdoor and recreation, and tourism purposes.`,
        "para5": `This brings about a need for more hospitality business.Thus hospitality industry requires more manpower to run their businesses.
        `,
        "para6": `Whether you require permanent staff or contract staff or recruitment process outsourcing, meridahr can help you.We can also help in grievances of staff and payroll processing of your employees.We 
        are up to  date with the trends in the hospitality industry and know the manpower requirement for the hospitality industry. Merida can help you in recruiting and staffing employees so that you get skilled manpower as we carefully select skilled staff for your manpower 
        needs..`,
        // "heading2": "Why Choose Merida HR Consulting for RPO?",
        "headin2content": [
            {
                "title": "In-Depth Industry Understanding",
                "content": [`Sector Expertise: Developing a deep understanding of the IT industry, including its trends, technologies, and skill 
                    requirements.`,
                    `Role-Specific Insights: Understanding the nuances of different IT roles, from software engineers and system administrators to IT support and cyber security 
                    experts.`,]
            },
            {
                "title": "Customized Recruitment Strategies",
                "content": [`Targeted Sourcing: Using specialized job boards, tech communities, and social media platforms
                     (like LinkedIn and Git-Hub) to find candidates with the right technical skills.`,
                    `Industry Networks: Leveraging industry connections and attending tech events to access passive candidates who may not be actively seeking new 
                    opportunities but are open to them.`,]
            },
            {
                "title": "Scalable Services",
                "content": [`Targeted Sourcing: Using specialized job boards, tech communities, and social media platforms
                    (like LinkedIn and Git-Hub) to find candidates with the right technical skills.`,
                    `Industry Networks: Leveraging industry connections and attending tech events to access passive candidates who may not be actively seeking new 
                   opportunities but are open to them.`,]
            },
            {
                "title": " Rigorous Screening and Selection",
                "content": [`Technical Assessments: Implementing coding tests, technical problem-solving exercises, and practical assessments relevant to the IT roles being 
                    filled.`,
                    `Expert Interviews: Conducting technical interviews with a focus on problem-solving skills, familiarity with specific technologies, and understanding of industry best 
                    practices.`,
                    `Cultural Fit: Assessing candidates' fit with the client's company culture and team dynamics, ensuring they align with the organization’s values and working
                 style.`]
            },
            {
                "title": "Tailored Candidate Presentation",
                "content": [`TQualified Shortlists: Providing a curated list of candidates who meet the technical and cultural requirements of the client’s 
                    organization.`,
                    `Detailed Profiles: Presenting comprehensive candidate profiles that include technical skills, experience, and interview 
                    feedback.`,]
            },
            {
                "title": "Offer Management and Negotiation",
                "content": [`Competitive Offers: Assisting in creating and negotiating competitive offers that reflect industry standards and attract top IT 
                    talent.`,
                    `On-boarding Support: Facilitating a smooth on-boarding process, including orientation, training, and integration into the client’s IT 
                    environment.`,]
            },
            {
                "title": "Post-Hire Support",
                "content": [`Integration Check-Ins: Regular follow-ups with both the client and new hires to ensure successful integration and address any potential 
                    issues.`,
                    `Feedback Loop: Collecting feedback from both parties to continuously improve the recruitment process and adjust strategies as 
                    needed.`,]
            },
            {
                "title": " Value-Added Services",
                "content": [`Market Intelligence: Providing insights into salary benchmarks, industry trends, and competitive analysis to help clients make informed hiring 
                    decisions.`,
                    `Talent Pool Management: Maintaining a database of qualified IT professionals for future hiring needs, ensuring quick access to potential candidates as requirements 
                    arise.`,]
            }
        ],
        "heading3": "Benefits for IT Clients",
        "heading3content": [
            {
                "title": "Specialized Expertise",
                "content": ` Access to a recruitment team with specialized knowledge in IT, ensuring a better understanding of the technical requirements and industry 
                standards.`},
            {
                "title": "Efficiency",
                'content': `Streamlined recruitment processes that save time and resources while delivering high-quality 
                candidates.` },
            {
                "title": "Quality Talent",
                "content": `Identifying and attracting top IT professionals who can contribute effectively to the client’s projects and organizational 
                goals.`       },
            {
                "title": "Scalability",
                "content": `Ability to scale recruitment efforts up or down based on the client’s evolving needs, whether for temporary projects or long-term 
                growth.`}
        ],
        // "heading4": "Experience the Merida HR Consulting Difference Today!",
        "heading4content": "By focusing on these key areas, Merida HR can effectively address the unique challenges of IT staffing and provide tailored solutions that meet the needs of IT clients.",
        "img1": "../Assest/rpo1.jpeg",
        "img2": "../Assest/rpo2.jpg",
        "img3": "../Assest/rpo3.jpg"
    },
    {
        "id": 2,
        "SID": "pharma",
        "title": "Pharma",
        "icon": "../Assest/contract.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Trusted Partner in Contract Staffing",
        "para1": `When Merida HR caters to the pharmaceutical industry, the focus is on addressing the unique staffing needs of a sector that involves specialized knowledge, compliance with stringent regulations, and a commitment to innovation and patient care. Here’s how Merida HR might tailor its services for pharmaceutical 
        clients `,
        "para2": `The USA accounts for more than a quarter of Indian pharmaceutical exports.Around 30% of  the generic medicines supplied to the USA are from India.Some of the major countries India  
        exports are USA, UK, South Africa, Russia and Nigeria.
        `,
        "para3": `India has allowed 100 percent FDI for foreign companies. India has around 3000 pharma companies with 10,500 manufacturing facilities.
        `,
        "para4": `Some of the major pharma companies  in India are Sun, Cipla,Zydus,Pfizer,Torrent Pharmaceuticals, Dr Reddys, etc.
        `,
        "para5": `Indian pharmaceutical industry growth started with the introduction of patents act in 1970 and drug policy of 1978.These acts set up a stage  for indian companies to establish themselves in the international markets.The 1984 Hatch-Waxman Act in the US boosted the production  of generic drugs benefitting indian drug makers.
        `,
        "para6": `A large volume of imported medical devices and supplies will be made in India.With the opening of the economy in 1991 and removal of licensing restrictions ,helped Indian Pharmaceutical companies establish themselves on the global platform.
        `,
        "para7": `This growth has brought about immense job opportunities for pharma professionals in India.
        `,
        // "heading2": "Why Choose  Merida HR Consulting for Contract Staffing?",
        "headin2content": [
            {
                "title": "Understanding Pharmaceutical Industry Needs",
                "content": [`Sector Knowledge: Gaining expertise in the pharmaceutical industry, including its various sectors such as research and development, manufacturing, quality assurance, clinical trials, and regulatory
                     affairs.`,
                    `Role Specificity: Understanding the specific roles within pharma, such as researchers, clinical trial managers, regulatory affairs specialists, and quality control
                     analysts.`,]
            },
            {
                "title": "Customized Recruitment Strategies",
                "content": [`Targeted Sourcing: Utilizing specialized job boards, industry-specific networks, and professional associations to find candidates with relevant pharmaceutical 
                    experience.`,
                    `Industry Events: Engaging with industry conferences, seminars, and trade shows to connect with potential candidates and stay abreast of industry 
                    trends.`,]
            },
            {
                "title": "Screening and Selection",
                "content": [`Technical Assessments: Implementing assessments and tests that are relevant to pharmaceutical roles, such as scientific knowledge, regulatory compliance, and analytical 
                    skills.`,
                    `Behavioral Interviews: Conducting interviews that evaluate candidates’ problem-solving abilities, attention to detail, and understanding of industry-specific 
                    practices.`,
                    `Regulatory Compliance: Ensuring candidates are knowledgeable about and compliant with regulations such as FDA guidelines, Good Manufacturing Practices (GMP), and Good Clinical Practice 
                (GCP).`]
            },
            {
                "title": "Candidate Presentation",
                "content": [`Curated Shortlists: Providing a shortlist of candidates who meet the technical, regulatory, and cultural requirements of pharmaceutical 
                       roles.`,
                    `Detailed Profiles: Offering comprehensive profiles that include candidates’ educational background, experience, skills, and their fit for the client’s 
                    organization.`,
                ]
            },
            {
                "title": "Offer Management and On-Boarding",
                "content": [`Competitive Offers: Assisting in crafting competitive job offers that reflect industry standards for pharmaceutical roles, including salary, benefits, and 
                    incentives.`,
                    `On-boarding Support: Facilitating a smooth onboarding process to ensure new hires understand the client’s operations, compliance requirements, and industry-specific 
                    protocols.`,
                ]
            },
            {
                "title": "Post-Hire Support",
                "content": [`Integration Check-Ins: Regular follow-ups with both the client and new hires to address any issues and ensure successful integration into the pharmaceutical 
                    environment.`,
                    `Feedback Collection: Gathering feedback to refine recruitment processes and improve future hiring 
                    strategies.`,
                ]
            },
            {
                "title": " Value-Added Services",
                "content": [`Market Intelligence: Providing insights into industry trends, salary benchmarks, and competitive analysis to help clients make informed hiring 
                    decisions.`,
                    `Talent Pool Management: Maintaining a database of pre-screened pharmaceutical professionals for quick access to qualified candidates as new needs 
                    arise.`,
                ]
            }
        ],
        "heading3": "Benefits for Pharmaceutical Clients",
        "heading3content": [
            {
                "title": "Specialized Knowledge:",
                "content": "Access to a recruitment team with a deep understanding of the pharmaceutical industry, ensuring alignment with the client’s specific needs and industry standards."
            },
            {
                "title": "Efficient Recruitment",
                'content': 'Streamlined processes that save time and resources while delivering high-quality candidates with the right expertise and experience.'
            },
            {
                "title": "Regulatory Compliance",
                "content": `Ensuring candidates are well-versed in pharmaceutical regulations and compliance requirements, which is crucial for maintaining industry standards.`
            },
            {
                "title": "Scalability",
                "content": `Flexibility to adjust recruitment efforts based on the client’s evolving needs, whether for research projects, manufacturing demands, or regulatory changes.`
            }
        ],
        // "heading4": "Experience the  Merida HR Consulting Advantage Today!",
        "heading4content": "By focusing on these areas, Merida HR can effectively support pharmaceutical clients in finding and managing the right talent to drive innovation, maintain compliance, and achieve business objectives within the highly regulated and dynamic pharmaceutical sector.",
        "img1": "../Assest/ContractStaffingSolutions1.jpeg",
        "img2": "../Assest/ContractStaffingSolutions2.jpeg",
        "img3": "../Assest/ContractStaffingSolutions3.jpeg"
    },
    // {
    //     "id": 3,
    //     "SID": "E-Commerce",
    //     "title": "E-Commerce",
    //     "icon": "../Assest/profile.png",
    //     "heading1": "Welcome to  Merida HR Consulting – Your Premier Partner in Permanent Staffing",
    //     "para1": `The E-Commerce market in India is growing rapidly.With access to internet and, internet connections being cheaper there are more people using the internet.With  lot of people using smartphones, the internet access is within fingertip reach now.
    //     `,
    //     "para2": `The  other major boost to e-commerce is businesses going online and giving competitive prices online.Thus there is increase in online shopping.People are buying clothes, gadgets, groceries, books etc online.
    //     `,
    //     "para3": `The major online market breakup is business to business (b2b), Business to consumer ( b2c),
    //     Consumer to consumer(c2c) and others.Digital wallet plays an important role in  e-commerce payment.With the increase of internet usage and smartphones the  digital wallet transactions have increased.
    //     `,
    //     "para4": `This is possible due to advancement of technology, govt initiatives and changes in consumer preferences.
    //     `,
    //     "para5": `This has led to more  ecommerce businesses and growth in e-commerce jobs and related jobs.Now there is increase of online purchases in tier 1 and tier 2  cities in India.
    //     `,
    //     "para6": `Since there is still a lower  penetration of  e-commerce there is  automatic scope  and for growth.This has  led to a demand for e-commerce jobs.The Indian e-commerce market size
    //     Is set to reach US$ 259 billion by 2032 ( source: IMARC).
    //     `,
    //     "para7": `Some of the top  e-commerce companies are Amazon, Flipkart,Indiamart, Firstcry etc.
    //     `,
    //     "heading2": "Why Choose  Merida HR Consulting for Permanent Staffing?",
    //     "headin2content": [
    //         {
    //             "title": "Expert Talent Acquisition",
    //             "content": "With 2 decades of experience in the industry, our dedicated team of recruiters possesses the expertise to identify, attract, and secure top-notch talent for your permanent positions."
    //         },
    //         {
    //             "title": "Comprehensive Screening Process",
    //             "content": "We go beyond resumes and conduct in-depth assessments to ensure that the candidates not only possess the necessary skills but also fit seamlessly into your company culture."
    //         },
    //         {
    //             "title": "Customised Recruitment Strategies",
    //             "content": "Every business is unique, and so are its hiring needs. We work closely with you to understand your organisational structure and goals, designing our recruitment strategies to meet your specific requirements."
    //         },
    //         {
    //             "title": "Time-Effective Solutions",
    //             "content": "Our well organised recruitment process is designed to save you time without compromising the quality of talent. We understand the urgency of permanent placements and strive to provide efficient solutions."
    //         },
    //         {
    //             "title": "Commitment to Quality",
    //             "content": "Our commitment to delivering quality staffing solutions is unwavering. We believe in forging lasting partnerships by consistently delivering candidates who contribute significantly to your company's success."
    //         }
    //     ],
    //     "heading3": "How  Merida HR Consulting Permanent Staffing Works:",
    //     "heading3content": [
    //         {
    //             "title": "Needs Analysis",
    //             "content": "Our first step is to conduct a comprehensive analysis of your permanent staffing needs. We work closely with your team to understand the skill sets, qualifications, and cultural fit you are seeking."
    //         },
    //         {
    //             "title": "Candidate Sourcing",
    //             'content': 'Utilising our extensive network and advanced recruitment tools, we identify and engage with potential candidates who match your specific requirements.'
    //         },
    //         {
    //             "title": "Rigorous Screening",
    //             "content": "We conduct thorough interviews, skills assessments, and reference checks to ensure that each candidate not only meets but exceeds your expectations."
    //         },
    //         {
    //             "title": "Presentation and Selection",
    //             "content": "We present you with a carefully curated selection of candidates, giving you the opportunity to make informed decisions on the individuals who will become integral members of your team."
    //         }
    //     ],
    //     "heading4": "Partner with  Merida HR Consulting for Exceptional Permanent Staffing Solutions",
    //     "heading4content": "Ready to build a lasting, high-performing team? Choose  Merida HR Consulting as your trusted partner for Permanent Staffing solutions. Contact us today to discuss your hiring needs and embark on a journey to secure top-tier talent that drives your business forward.",
    //     "img1": "../Assest/PermanentStaffing1.jpeg",
    //     "img2": "../Assest/PermanentStaffing2.jpeg",
    //     "img3": "../Assest/PermanentStaffing3.jpeg"
    // },
    {
        "id": 4,
        "SID": "banking",
        "title": "Banking",
        "icon": "../Assest/salary.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Reliable Partner in Seamless Payroll Processing",
        "para1": `When Merida HR caters to the banking industry, it provides specialized staffing solutions tailored to the unique needs and regulatory requirements of the financial sector. Here’s how Merida HR might structure its services for banking clients `,
        "para2": `Some of the jobs in life sciences are biomedical scientist, biotechnologist, biochemist,computational biologist, microbiologist, clinical research associate, bioinformatician,
        Research assistant.
        `,
        "para3": `From 2018  to 2022, Bengaluru, Delhi and Hyderabad were the top preferred destinations by international companies to set up their offices  and plants here.These  cities have large LS clusters, top R & D labs,incubation centers  and research institutions.
        `,
        "para4": `Gujrat, Maharashtra and Himachal Pradesh are among the top manufacturing hubs in India.
        To grow the life science industry the life science companies are focussing on low cost, talent recruitment, and scalability.India has adequate talent in this sector.
        `,
        "para5": `Some of the life science companies are IQUIA, Novartis,Thermo-Fisher Scientific, etc.
        `,
        "para6": `The  growth of life science sector depends largely on research and development.Life science companies are adopting technology , data and AI  to develop themselves and grow.India ranks  second globally in terms of number of US-FDA certified plants located outside the US.
        `,
        // "heading2": "Why Choose  Merida HR Consulting for Payroll Processing?",
        "headin2content": [
            {
                "title": "Understanding Banking Industry Needs",
                "content": [`Industry Insight: Gaining deep knowledge of the banking sector, including its various functions such as retail banking, corporate banking, investment banking, and financial 
                    services.`,
                    `Role Specificity: Understanding the specific roles within banking, from tellers and loan officers to compliance officers and risk 
                    managers.`,
                ]
            },
            {
                "title": "Customized Recruitment Strategies",
                "content": [`Targeted Sourcing: Using specialized job boards, financial industry networks, and professional associations to find candidates with relevant banking 
                    experience.`,
                    `Industry Events: Attending banking conferences, seminars, and networking events to connect with potential candidates and stay updated on industry 
                    trends.`,
                ]
            },
            {
                "title": "Screening and Selection",
                "content": [`Technical Assessments: Implementing tests and assessments relevant to banking roles, such as financial analysis, risk assessment, and regulatory 
                    knowledge.`,
                    `Behavioral Interviews: Conducting interviews to evaluate candidates’ decision-making abilities, customer service skills, and understanding of banking regulations and 
                    compliance.`,
                    `Regulatory Compliance: Ensuring candidates are aware of and adhere to banking regulations, such as Anti-Money Laundering (AML) and Know Your Customer (KYC)
                     requirements.`
                ]
            },
            {
                "title": "Candidate Presentation",
                "content": [`Curated Shortlists: Providing a shortlist of candidates who meet the technical and regulatory requirements of banking 
                    roles.`,
                    `Comprehensive Profiles: Offering detailed profiles with insights into candidates’ skills, experience, and fit for the client’s banking 
                    environment.`,
                ]
            },
            {
                "title": "Offer Management and On-Boarding",
                "content": [`Competitive Offers: Assisting in crafting competitive job offers that align with industry standards for banking roles, including salary, benefits, and performance 
                    incentives.`,
                    `On-Boarding Support: Facilitating a smooth on-boarding process to ensure new hires are quickly integrated into the client’s operations and understand banking 
                    protocols.`,
                ]
            },
            {
                "title": " Post-Hire Support",
                "content": [`Integration Check-Ins: Regularly checking in with both the client and new hires to address any issues and ensure successful integration into the banking 
                    environment.`,
                    `Feedback Collection: Gathering feedback from both parties to refine recruitment strategies and improve future hiring 
                    processes.`,

                ]
            },
            {
                "title": "Value-Added Services",
                "content": [`Market Intelligence: Providing insights into industry trends, salary benchmarks, and competitive analysis to help clients make informed hiring decisions.`,
                    `Talent Pool Management: Maintaining a database of pre-screened banking professionals for quick access when new roles 
                    arise.`,
                    `Training and Development: Offering support for ongoing training and professional development to ensure employees stay current with industry regulations and best 
                    practices.`
                ]
            },
        ],
        "heading3": "Benefits for Banking Clients",
        "heading3content": [
            {
                "title": "Specialized Knowledge",
                "content": "Access to a recruitment team with deep understanding of the banking sector, ensuring a better alignment with the client’s needs."
            },
            {
                "title": "Efficient Recruitment",
                'content': 'Streamlined recruitment processes that save time and resources while delivering high-quality candidates with relevant banking experience.'
            },
            {
                "title": "Regulatory Compliance",
                "content": "Ensuring candidates are knowledgeable about and compliant with banking regulations and standards."
            },
            {
                "title": "Scalability",
                "content": " Ability to scale recruitment efforts based on the client’s needs, whether for temporary staffing, seasonal demands, or long-term growth."
            }
        ],
        // "heading4": "Partner with  Merida HR Consulting for Effortless Payroll Processing",
        "heading4content": "By focusing on these areas, Merida HR can effectively support banking clients in finding and managing the right talent to meet the complex and evolving demands of the financial sector.",
        "img1": "../Assest/payroll1.jpeg",
        "img2": "../Assest/payroll2.jpeg",
        "img3": "../Assest/payroll3.jpeg"
    },
    {
        "id": 5,
        "SID": "information-technology-enabled-services",
        "title": "Information Technology Enabled Services (ITES)",
        "icon": "../Assest/salary.png",
        "heading1": "Welcome to  Merida HR Consulting – Your Reliable Partner in Seamless Payroll Processing",
        "para1": `When Merida HR caters to the ITES (Information Technology Enabled Services) industry, the focus is on recruiting and managing talent for services that combine IT with business processes. ITES encompasses areas like customer support, business process outsourcing (BPO), data management, and more. Here’s how Merida HR might tailor its services for ITES 
        clients`,
        "para2": `Before, the shoppers shopped in Kirana Stores, Street shops, weekly markets, small shops  etc.
        In 2000 the rise of shopping malls happened.You had products, eating outlets and entertainment in shopping malls.
        `,
        "para3": `However, late 2000’s to early 2010’s led to the rise of e-commerce.This led to the decrease in mall popularity.The modern retail formats started in 2010 onwards. Ecommerce,omni-channel retailing i.e using both offline and online channels to sell ,pop up stores (stores that are  opened for a short period of time), factory outlets ( i.e discounted,seconds) were adopted.
        `,
        "para4": `The rise of e-commerce led to the opening of e-commerce companies like Amazon and flipkart etc.The growth in e-commerce happened because of cheap internet access , rising middle class,  usage of smartphones and government’s pro business policies.
        `,
        "para5": `Omni-channel retailing has gained significance in India.Retail chains like Reliance  retail, Tata--croma and Flipkart  have omni-channel  retailing bridging the gap between online and offline shopping.
        `,
        "para6": `The key players in the retail sector are the Kirana Stores, Super markets, Hyper markets( larger than supermarkets),online stores and specialty stores.
        `,
        "para7": `The future of the Indian retail sector will be more technology led e-commerce.More international brands,as consumers want high quality brands .More online and offline purchases.The trends show that more online purchases are happening in tier 2 and tier 3 cities opening up markets for retail companies.This can lead to more online and offline retail jobs.
        `,
        // "heading2": "Why Choose  Merida HR Consulting for Payroll Processing?",
        "headin2content": [
            {
                "title": "Understanding ITES Industry Needs",
                "content": [`Sector Knowledge: Gaining a deep understanding of ITES operations, including common services such as customer support, technical support, data entry, and back-office 
                    processes.`, `Role Specificity: Recognizing the distinct roles within ITES, from customer service representatives and technical support agents to process managers and data 
                    analysts.`  ]
            },
            {
                "title": "Customized Recruitment Strategies",
                "content": [`Targeted Sourcing: Using job boards, industry-specific platforms, and social media to find candidates suited for ITES roles. Engaging with local job fairs and career events focused on 
                    ITES.` , `Industry Networking: Leveraging connections and networks within the ITES sector to access both active and passive 
                    candidates.` ]
            },
            {
                "title": "Screening and Selection",
                "content": [`Skill Assessments: Conducting assessments that test relevant skills, such as communication abilities, problem-solving, and technical proficiency, tailored to ITES roles.`
                    , `Behavioural Interviews: Performing interviews that evaluate candidates' customer service skills, adaptability, and ability to handle high-pressure situations.`,
                    `Cultural Fit: Ensuring candidates align with the client’s organizational culture and can handle the dynamic nature of ITES environments.`
                ]
            },
            {
                "title": "Candidate Presentation",
                "content": [`Curated Shortlists: Providing a list of candidates who meet the technical and interpersonal requirements of ITES roles.`
                    , `Detailed Insights: Offering comprehensive profiles with insights into candidates’ skills, experience, and potential fit for the client’s needs.`
                ]
            },
            {
                "title": "Offer Management and On-Boarding",
                "content": [`Negotiation: Assisting with crafting and negotiating job offers that are competitive within the ITES sector, including salary, benefits, and incentives.`,
                    `On-boarding Support: Facilitating smooth on-boarding processes to help new hires quickly adapt to the client’s work environment and operational procedures.`
                ]
            },
            {
                "title": "Post-Hire Follow-Up",
                "content": [`Integration Support: Regularly checking in with both the client and new hires to address any issues and ensure successful integration.`,
                    `Feedback Collection: Gathering feedback from clients and employees to refine recruitment processes and improve future staffing strategies.`
                ]
            },
            {
                "title": "Value-Added Services",
                "content": [`Market Insights: Providing information on industry trends, salary benchmarks, and best practices to help clients make informed hiring decisions.`,
                    `Talent Pool Management: Maintaining a database of pre-screened candidates for ITES roles to quickly fill future openings as they arise.`
                ]
            },

        ],
        "heading3": "Benefits for ITES Clients",
        "heading3content": [
            {
                "title": "Specialized Knowledge",
                "content": " Access to recruiters with expertise in ITES, ensuring a better understanding of the sector’s specific needs and challenges."
            },
            {
                "title": "Efficient Recruitment",
                'content': 'Streamlined processes that save time and resources while delivering high-quality candidates who fit well within the ITES environment.'
            },
            {
                "title": "Quality Talent",
                "content": " Finding and hiring candidates who have the right mix of technical skills and soft skills required for ITES roles."
            },
            {
                "title": "Scalability",
                "content": " Flexibility to adjust recruitment efforts based on the client’s business needs, whether for temporary projects, seasonal demands, or long-term growth."
            }
        ],
        // "heading4": "Partner with  Merida HR Consulting for Effortless Payroll Processing",
        "heading4content": "By focusing on these areas, Merida HR can effectively support ITES clients in finding and managing the right talent to drive their business success and operational efficiency.",
        "img1": "../Assest/rpo1.jpeg",
        "img2": "../Assest/rpo2.jpg",
        "img3": "../Assest/rpo3.jpg"
    },
    // {
    //     "id": 6,
    //     "SID": "Telecom",
    //     "title": "Telecom",
    //     "icon": "../Assest/salary.png",
    //     "heading1": "Welcome to  Merida HR Consulting – Your Reliable Partner in Seamless Payroll Processing",
    //     "para1": `The Indian Telecom Industry has grown many fold over the years.India is one of the biggest consumers of data worldwide.The broadband services include  wi-fi,DSLs, Fiber and satellite.It is the transmission of high quality of data under bandwidth.
    //     `,
    //     "para2": `5G is the 5th generation mobile network.It is the new global standard after 1G,2G, 3G, 4G.
    //     5G enables a new kind of network that can connect virtually everything including machines, objects, devices with high quality data speed , reliability, etc.
    //     `,
    //     "para3": `5G is used in smartphones ,VR and AR. 5G can be used to remote control critical infrastructure, vehicles and medical procedures.It is used to connect a massive  number of embedded sensors in everything by the ability to lower data, rates , power and mobility leading to low cost connectivity.
    //     `,
    //     "para4": `Some of the telecom operators in India are ,airtel india, BSNL, Reliance jio etc
    //     `,
    //     "para5": `
    //     India has shifted from an Import intensive  economy to becoming self-reliant in manufacturing specially for telecom equipment.This sector provides employment for 4 million skilled people already.
    //     `,
    //     "para6": `By 2025, India will need 22 million skilled workforce in 5G centric technologies such as internet of things(IoT), Artificial Intelligence (AI), Robotics and cloud computing.( source :IBEF).
    //     FDI in the telecom sector has stood to US $ 39.31 billion between april 2000 to sept 2023(source :IBEF)
    //     `,
    //     "heading2": "Why Choose  Merida HR Consulting for Payroll Processing?",
    //     "headin2content": [
    //         {
    //             "title": "Accuracy and Compliance",
    //             "content": "Eliminate payroll errors and ensure compliance with ever-changing regulations. Our expert payroll processing team stays up-to-date with the latest tax laws and labour regulations to guarantee accurate and legally compliant payroll processing."
    //         },
    //         {
    //             "title": "Time and Cost Efficiency",
    //             "content": "Free up valuable time and resources by outsourcing your payroll processing to us. Our streamlined processes and advanced payroll technology enable us to handle complex payroll tasks efficiently, saving you both time and money."
    //         },
    //         {
    //             "title": "Customised Solutions",
    //             "content": "Recognizing that every business is unique, we tailor our payroll processing services to meet your specific needs. Whether you have a small startup or a large enterprise, we have the flexibility to adapt to your requirements."
    //         },
    //         {
    //             "title": "Data Security",
    //             "content": "Your sensitive payroll data is in safe hands with  Merida HR Consulting. We prioritize data security and implement robust measures to safeguard your information, ensuring confidentiality and compliance with data protection standards."
    //         },
    //         {
    //             "title": "Scalability",
    //             "content": "As your business grows, our payroll processing services grow with you. Our scalable solutions ensure that we can accommodate changes in your workforce and adapt to the evolving needs of your organisation."
    //         }
    //     ],
    //     "heading3": "How  Merida HR Consulting Payroll Processing Works:",
    //     "heading3content": [
    //         {
    //             "title": "Data Collection",
    //             "content": "We start by collecting all relevant payroll data, including employee hours, benefits, and deductions. Our user-friendly systems allow for seamless data submission."
    //         },
    //         {
    //             "title": "Calculation and Processing",
    //             'content': 'Our advanced payroll software performs accurate calculations, considering tax deductions, benefits, and other variables. We ensure that your employees receive their pay on time, every time.'
    //         },
    //         {
    //             "title": "Compliance Checks",
    //             "content": "Our team conducts thorough compliance checks to ensure that your payroll aligns with federal, state, and local regulations. This includes tax filings, wage and hour laws, and any other applicable regulations."
    //         },
    //         {
    //             "title": "Reporting and Analytics",
    //             "content": "Receive detailed payroll reports and analytics that provide valuable insights into your labour costs, helping you make informed business decisions."
    //         }
    //     ],
    //     "heading4": "Partner with  Merida HR Consulting for Effortless Payroll Processing",
    //     "heading4content": "Experience the ease and efficiency of outsourcing your payroll processing to  Merida HR Consulting. Contact us today to explore how our tailored payroll solutions can benefit your business, allowing you to focus on what you do best.",
    //     "img1": "../Assest/payroll2.jpeg",
    //     "img2": "../Assest/payroll2.jpeg",
    //     "img3": "../Assest/payroll3.jpeg"
    // },
    // {
    //     "id": 7,
    //     "SID": "Software-Development",
    //     "title": "Software Development",
    //     "icon": "../Assest/salary.png",
    //     "heading1": "Welcome to  Merida HR Consulting – Your Reliable Partner in Seamless Payroll Processing",
    //     "para1": `At  Merida HR Consulting, we understand the critical role that payroll plays in your business. Our comprehensive Payroll Processing services are designed to take the burden off your shoulders, ensuring accuracy, compliance, and efficiency in managing your payroll functions.
    //     `,
    //     "para2": `The IBEF believes   revenues worth  $ 3. 50 billion will come from the IT Sector by 2025.The IT sector is predicted to contribute  up to 10% of Indian GDP by 2025.The software industry uses these resources i.e low cost and highly skilled labor , in which, India enjoys  competitive advantage. 
    //     `,
    //     "para3": `The pwc India, has announced that it will be planning to hire 10,000 employees in the cloud and digital technologies in the next few  years.( source:IBEF)
    //     .`,
    //     "para4": `Software product sector reached $13.3 billion in FY 2022. Markets enabled by Saas, enterprise digitalization and IMBS  have grown.Enterprises movements towards cloud have intensified . ( source:Nasscom).
    //     `,
    //     "para5": `After the year 2000 an increasing number  of multinational companies saw India's potential in software services and set up the IT and R&D facilities here.With the experience of software development services to offshore companies ,Indian software companies are now starting their own startups in india.
    //     `,
    //     "para6": `More recently the Indian Software ecosystem is providing end to end products along with building and managing complex IT systems for global enterprises.
    //     `,
    //     "para7": `The major technologies of the future in IT sector will be using cloud computing, data analytics,artificial intelligence( AI), cyber security, etc
    //     `,
    //     "para8": `Some of the major IT companies that are recruiting software engineers are Tata Consultancy Services, Infosys and Wipro.
    //     `,
    //     "heading2": "Why Choose  Merida HR Consulting for Payroll Processing?",
    //     "headin2content": [
    //         {
    //             "title": "Accuracy and Compliance",
    //             "content": "Eliminate payroll errors and ensure compliance with ever-changing regulations. Our expert payroll processing team stays up-to-date with the latest tax laws and labour regulations to guarantee accurate and legally compliant payroll processing."
    //         },
    //         {
    //             "title": "Time and Cost Efficiency",
    //             "content": "Free up valuable time and resources by outsourcing your payroll processing to us. Our streamlined processes and advanced payroll technology enable us to handle complex payroll tasks efficiently, saving you both time and money."
    //         },
    //         {
    //             "title": "Customised Solutions",
    //             "content": "Recognizing that every business is unique, we tailor our payroll processing services to meet your specific needs. Whether you have a small startup or a large enterprise, we have the flexibility to adapt to your requirements."
    //         },
    //         {
    //             "title": "Data Security",
    //             "content": "Your sensitive payroll data is in safe hands with  Merida HR Consulting. We prioritize data security and implement robust measures to safeguard your information, ensuring confidentiality and compliance with data protection standards."
    //         },
    //         {
    //             "title": "Scalability",
    //             "content": "As your business grows, our payroll processing services grow with you. Our scalable solutions ensure that we can accommodate changes in your workforce and adapt to the evolving needs of your organisation."
    //         }
    //     ],
    //     "heading3": "How  Merida HR Consulting Payroll Processing Works:",
    //     "heading3content": [
    //         {
    //             "title": "Data Collection",
    //             "content": "We start by collecting all relevant payroll data, including employee hours, benefits, and deductions. Our user-friendly systems allow for seamless data submission."
    //         },
    //         {
    //             "title": "Calculation and Processing",
    //             'content': 'Our advanced payroll software performs accurate calculations, considering tax deductions, benefits, and other variables. We ensure that your employees receive their pay on time, every time.'
    //         },
    //         {
    //             "title": "Compliance Checks",
    //             "content": "Our team conducts thorough compliance checks to ensure that your payroll aligns with federal, state, and local regulations. This includes tax filings, wage and hour laws, and any other applicable regulations."
    //         },
    //         {
    //             "title": "Reporting and Analytics",
    //             "content": "Receive detailed payroll reports and analytics that provide valuable insights into your labour costs, helping you make informed business decisions."
    //         }
    //     ],
    //     "heading4": "Partner with  Merida HR Consulting for Effortless Payroll Processing",
    //     "heading4content": "Experience the ease and efficiency of outsourcing your payroll processing to  Merida HR Consulting. Contact us today to explore how our tailored payroll solutions can benefit your business, allowing you to focus on what you do best.",
    //     "img1": "../Assest/PermanentStaffing1.jpeg",
    //     "img2": "../Assest/PermanentStaffing2.jpeg",
    //     "img3": "../Assest/PermanentStaffing3.jpeg"
    // }
]


export { serviceContent, industrycontent }