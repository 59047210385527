import React from 'react'
import RightArrow from '../../SVG/RightArrow'

const HomeServiceCard = ({ bg, cir, txt, br }) => {
    return (
        <div className={`rounded max-w-[18rem] min-h-[20rem] ${txt ? txt : 'text-slate-800'}
         ${bg ? bg : "bg-fuchsia-400"} bg-opacity-80 p-4  text-sm`} >
            <article className='flex justify-between ' >
                <div className={`w-4 h-4 rounded-full ${cir ? cir : "bg-black"} bg-opacity-80`} > </div>
                <p className='mb-0 '>5 min read </p>
            </article>
            <article className='flex gap-1 my-2 items-center ' >
                <p className='text-xl fw-semibold line-clamp-4 mb-0' >The Latest Trends and Strategies with a Digital Marketing Agency</p>
                <span className='-rotate-45 w-fit h-fit ' ><RightArrow size={30} /> </span>
            </article>
            <hr className='border-2 border-slate-900 opacity-80 ' />
            <p className='line-clamp-3 ' >
                Working with this digital marketing agency has been a true partnership. They have taken the time to
                understand our business and industry
            </p>
            <article className='flex justify-between items-end ' >
                <img className='w-28 ' src={require('../../Assest/multiDots.webp')} alt="MultiDots" />
                <span className={`p-1 rounded-full px-2 ${br ? bg : "border-slate-800"} border-2 `} ><RightArrow /> </span>
            </article>
        </div>
    )
}

export default HomeServiceCard