import React from 'react'

const WorkSamrter = () => {
    return (
        <div className='btngrd xl:min-h-[40vh] poppins' >
            <main className='container row mx-auto py-5' >
                <section className='col-lg-6 ' >
                    <h5 className='text-white text-4xl ' >Work smarter <br />
                        with easy access for user... </h5>
                    <article className='my-5 ' >

                        <div className='flex gap-4 my-2 ' >
                            <span className='bg-white fw-bold text-blue-700 
                        text-3xl rounded-full
                         w-20 h-20 flex items-center justify-center ' >
                                1
                            </span>
                            <div className='flex-1   ' >
                                <h4 className='text-white ' >Join us </h4>
                                <p className='text-slate-200 text-sm line-clamp-2 w-4/5 ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni dolor debitis ipsam vitae necessitatibus asperiores hic
                                    dolorem nam esse temporibus! </p>
                            </div>
                        </div>

                        <div className='flex gap-4 my-2 ' >
                            <span className='bg-white fw-bold text-blue-700 
                        text-3xl rounded-full
                         w-20 h-20 flex items-center justify-center ' >
                                2
                            </span>
                            <div className='flex-1   ' >
                                <h4 className='text-white ' >Learn how to use </h4>
                                <p className='text-slate-200 text-sm line-clamp-2 w-4/5 ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni dolor debitis ipsam vitae necessitatibus asperiores hic
                                    dolorem nam esse temporibus! </p>
                            </div>
                        </div>

                        <div className='flex gap-4 my-2 ' >
                            <span className='bg-white fw-bold text-blue-700 
                        text-3xl rounded-full
                         w-20 h-20 flex items-center justify-center ' >
                                3
                            </span>
                            <div className='flex-1   ' >
                                <h4 className='text-white ' >Track analytics </h4>
                                <p className='text-slate-200 text-sm line-clamp-2 w-4/5 ' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni dolor debitis ipsam vitae necessitatibus asperiores hic
                                    dolorem nam esse temporibus! </p>
                            </div>
                        </div>
                    </article>
                </section>
                <section className='col-lg-6 flex flex-col '  >
                    <button className='rounded-full p-3 px-4 text-blue-800 flex ms-auto bg-white fw-semibold' >
                        Get Started Now
                    </button>
                    {/* Video */}
                    <div className='w-4/5 rounded bg-white h-[300px] my-auto ' >

                    </div>
                </section>
            </main>
            {/* Easy to manage */}


        </div>
    )
}

export default WorkSamrter