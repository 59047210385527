import React from 'react'
import BannerCom from '../Component/ServiceComponent/BannerCom'
import Sectors from '../Component/IndustryComponent/Sectors'
import SectorContact from '../Component/IndustryComponent/SectorContact'
import SectorBanner from '../Component/IndustryComponent/SectorBanner'

const SectorsPage = () => {
    return (
        <div className='bg-slate-100 ' >
            <SectorBanner />
            <main className='container sticky top-0 bg-slate-100 py-3 poppins z-10 ' >

                <h6 className='text-3xl ' > Explore Sectors </h6>
                <p className='text-slate-500 text-sm ' >From standard consultations to specialized treatments, discover the range of services
                    offered at each room level. </p>
                <hr />
            </main>

            <Sectors bg="bg-slate-400"
                section="mini" scrollup="zero" />
            <Sectors pannel="bg-slate-200" bg="bg-slate-50" text="text-slate-800"
                section="zero" scrollup="first" scrolldown="mini" />
            <Sectors pannel="bg-slate-50" bg="bg-red-300" text="text-slate-800"
                section="first" scrollup="second2" scrolldown="zero" />
            <Sectors pannel="bg-orange-200" bg="bg-green-300" text="text-slate-800"
                section="second2" scrollup="third" scrolldown="first" />
            <Sectors pannel="bg-sky-200" bg="bg-sky-200" text="text-slate-800"
                section="third" scrolldown="second2" />
            <SectorContact />


        </div>
    )
}

export default SectorsPage